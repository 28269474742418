import { doRequest } from "./_helpers";
import { User } from "../types/apiTypes";
import BaseApi from "./_baseApi";

export type LoginResponse = {
  jwt: string,
  user: User,
}

export type authApi = {
  login: Function,
  profile: Function,
}

export default class Auth extends BaseApi {
  login(data): Promise<LoginResponse> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: '/auth/local',
      data: data,
    })
  }

  profile() {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: '/profile',
    })
  }
}