import { Pane } from "evergreen-ui";
import { Outlet } from "react-router-dom";
import SidebarLeft from "../common/SidebarLeft";
import Block from "../common/Block";
import MainNavigationMenu from "../nav/MainNavigationMenu";

export default function Layout() {
  return (
    <Pane display="flex">
      <Pane className={"min-w-[20rem]"}>
        <SidebarLeft>
          <MainNavigationMenu />
        </SidebarLeft>
      </Pane>
      <Block className="w-full p-5">
        <Outlet />
      </Block>
    </Pane>
  );
}