import { ActivityTypeOption } from "./apiTypes";

export const COUNTING_BIRDS: string = 'counting_birds';
export const COUNTING_BATS: string = 'counting_bats';

export interface ActivityTypeOptionMap {
    [key: string]: ActivityTypeOption,
}

export const ActivityTypeOptions: ActivityTypeOptionMap = {
    [COUNTING_BIRDS]: {
        label: 'Counting Birds',
        value: 'counting_birds',
        numberOfRounds: 5,
        minDaysBetweenRounds: 2,
    },
    [COUNTING_BATS]: {
        label: 'Counting Bats',
        value: 'counting_bats',
        numberOfRounds: 10,
        minDaysBetweenRounds: 2,
    }
}

export const mapToActivityOption = (type): ActivityTypeOption => ActivityTypeOptions[type] ?? null;