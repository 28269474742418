import Auth, { authApi } from "./_auth";
import { AxiosInstance } from "axios";
import AdminAreas from "./_adminAreas";
import AdminProjects from "./_adminProjects";
import AdminUsers from "./_adminUsers";
import AdminActivityTypes from "./_adminActivityTypes";

export type defaultApi = {
  create?: Function,
  findMany?: Function,
  findOne?: Function,
  remove?: Function,
  update?: Function,
}

export type ApiHooks = defaultApi | authApi;

export const apiObject = {
  auth: new Auth(),
  adminUsers: new AdminUsers(),
  adminProjects: new AdminProjects(),
  adminAreas: new AdminAreas(),
  adminActivityTypes: new AdminActivityTypes(),
};

export default function ApiInstance(axiosInstance: AxiosInstance): typeof apiObject {
  apiObject.auth.axiosInstance = axiosInstance
  apiObject.adminAreas.axiosInstance = axiosInstance
  apiObject.adminProjects.axiosInstance = axiosInstance
  apiObject.adminUsers.axiosInstance = axiosInstance
  apiObject.adminActivityTypes.axiosInstance = axiosInstance

  return apiObject;

    // auth: _auth(axiosInstance),
    // signup: _signup(axiosInstance),
    // adminUsers: _adminUsers(axiosInstance),
    // adminProjects: _adminProjects(axiosInstance),
    // adminAreas: _adminAreas(axiosInstance),
    // researchTypes: _researchTypes(axiosInstance),
    // areaActivityTypes: _areaActivityTypes(axiosInstance),
}