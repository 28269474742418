import { EmptyState, Heading, SearchIcon, Table } from "evergreen-ui";
import Block from "../../../components/common/Block";
import Actions from "../../../components/common/Actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLoadResource } from "../../../lib/request-hooks";
import { useApi } from "../../../context/AxiosContext";
import { Area } from "../../../types/apiTypes";
import StandardTable from "src/components/table/StandardTable";
import { AddressCellDisplay } from "../../../components/addresses/AddressCellDisplay";
import { AreaActivityTypesDisplayCell } from "../../../components/activity-types/AreaActivityTypesDisplay";

const useAreas = function () {
    const { projectId } = useParams();
    const [ areas, setAreas] = useState<Area[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const { apiInstance } = useApi();

    const request = () => apiInstance!.adminAreas.findMany(projectId);

    const setData = (areas: Area[]) => {
        setAreas(areas);
        setIsLoading(false)
    }

    return {
        ...useLoadResource(request, setData),
        isLoading,
        areas,
    };
}

export default function AreasPage() {
    const { projectId } = useParams();

    const { areas, isLoading  } = useAreas();
    const navigate = useNavigate();

    const navTo = (area) => {
        navigate(`/projects/${projectId}/areas/${area.id}`);
    }

    return <>
        <Heading marginBottom={20}>Areas</Heading>

        <Block>
            <Actions marginBottom={10}>
                <Link to={`/projects/${projectId}/areas/add`}>
                    <Actions.Button
                        appearance="primary">New Area
                    </Actions.Button>
                </Link>
            </Actions>
            <StandardTable isLoading={isLoading} data={areas} emptyState={<EmptyAreasState />}>
                <Table.Head>
                    <Table.TextHeaderCell maxWidth={80}>Id</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Activity Types</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Address</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                    {(areas || []).map((area) => (
                        <Table.Row key={area.id} isSelectable onSelect={() => { navTo(area)}}>
                            <Table.TextCell maxWidth={80}>{area.id}</Table.TextCell>
                            <Table.TextCell>{area.name}</Table.TextCell>
                            <Table.TextCell>
                                <AreaActivityTypesDisplayCell area={area} />
                            </Table.TextCell>
                            <Table.TextCell>
                                <AddressCellDisplay area={area} />
                            </Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </StandardTable>
        </Block>
    </>
}

function EmptyAreasState() {
    return (
        <EmptyState
            background="light"
            title="No areas found"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Areas appear when you add areas, use the button 'New Area'"
        />
    )
}
