import { ActivityType } from "../../types/apiTypes";
import { Pane, Spinner } from "evergreen-ui";
import { useActivityTypes } from "../../pages/projects/activity-types/ActivityTypesPage";
import MultiSelectInputField from "../formfields/MultiSelectInputField";

const ActivityTypesSelect = function ({ selected = [], setSelected}: ActivityTypesSelectProps) {
    const {
        activityTypes,
        isLoading,
    } = useActivityTypes();

    const makeLabeled = (items) => items.map((activityType) => {
        return {
            label: activityType.name,
            value: activityType.id.toString(),
            model: activityType,
        }
    })

    const selectedItems = makeLabeled(selected);
    const ops = makeLabeled(activityTypes);

    const setSelectedItems = (items) => {
        setSelected(items.map(v => v.model));
    }

    return <Pane>
        {isLoading &&
          <Pane className="flex justify-end">
            <Spinner size={20}/>
          </Pane>
        }
        <MultiSelectInputField
            selected={selectedItems}
            emptySelectText="<Activity Types>"
            options={ops}
            setSelected={setSelectedItems}
        ></MultiSelectInputField>
    </Pane>
}

export type ActivityTypesSelectProps = {
    projectId: string,
    selected: ActivityType[],
    setSelected: Function,
}

export default ActivityTypesSelect;
