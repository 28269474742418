import ApiError from "../api/ApiError";
import { useEffect, useRef, useState, useTransition } from "react";

export const useDoRequest = () => {
    const [isPending, startTransition] = useTransition();
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const [isLoadingLocal, setIsLoadingLocal] = useState<boolean | null>(null);
    const isLoadingRef = useRef<boolean | null>(null);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(() => {
        setIsLoading(isLoadingLocal)
    }, [isLoadingLocal])

    const handle = (promise, setData: Function | null = null) => {
        isLoadingRef.current = false;

        setIsLoadingLocal(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        return promise.then((data) => {
            if (setData) {
                setData(data).then(() => {
                    setIsLoading(false);
                });
            }

            return data;
        }).catch((e) => {
            if (e instanceof ApiError) {
                if (e.messages) {
                    setErrorMessage(e.messages[0]);
                }
            }

            startTransition(() => {
                setIsLoading(false);
            })

            throw e;
        })
    }

    return {
        handle,
        isLoading,
        setIsLoading,
        errorMessage,
        successMessage,
        setErrorMessage,
        setSuccessMessage,
    }
}

export const useLoadResource = function(request, setData, enabled = true) {
    const { handle, isLoading, setIsLoading, errorMessage, successMessage } = useDoRequest();

    useEffect(() => {
        let mounted = true;

        async function load() {
            const setDataCb = (data) => {
                return new Promise(( resolve ) => {
                    if (mounted) {
                        // todo could be dangerous to assume data.data
                        setData(data?.data || data);
                        resolve(data)
                    }
                })
            }

            return await handle(request(), setDataCb);
        }

        if (enabled) {
            load();
        } else {
            setIsLoading(false)
        }

        return () => {
            mounted = false;
        }
    }, [])

    return {
        isLoading,
        errorMessage,
        successMessage,
    }
}
