import { classNames } from '../../lib/functions';
import Block from "./Block";

const Form = function Form(props) {
    const { children, ...rest } = props

    const className = classNames('flex flex-col gap-5 max-w-4xl', props?.className);

    return (
        <Block className={className} {...rest}>
            {children}
        </Block>
    )
}

export default Form;
