import React, { ReactElement } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import Layout from "./components/layouts/Layout";
import LoginPage from "./pages/login/LoginPage";
import { useAuth } from "./context/AuthContext";
import DashboardPage from "./pages/dashboard/DashboardPage";
import { HasChildren } from "./providers/AuthProvider";
import UnAuthLayout from "./components/layouts/UnAuthLayout";
import UsersPage from "./pages/users/UsersPage";
import SettingsPage from "./pages/settings/DashboardPage";
import ProjectsPage from "./pages/projects/ProjectsPage";
import ProjectsEditPage from "./pages/projects/ProjectsEditPage";
import AreasPage from "./pages/projects/areas/AreasPage";
import AreaEditPage from "./pages/projects/areas/AreaEditPage";
import ProjectLayout from "./components/layouts/ProjectLayout";
import ActivityTypesPage from "./pages/projects/activity-types/ActivityTypesPage";
import ActivityTypesEditPage from "./pages/projects/activity-types/ActivityTypeEditPage";
import { useApp } from "./context/AppContext";
import { Pane, Paragraph, Spinner } from "evergreen-ui";
import Block from "./components/common/Block";

export const history = createBrowserHistory();

export default function AppRoutes() {
    const auth = useAuth();
    //
    // if (!auth.user) {
    //     return (
    //         <Routes>
    //         </Routes>
    //     )
    // }

    return (
        <Routes>
            <Route path="/">
                <Route element={<UnAuthLayout/>}>
                    <Route index path="/" element={<RouteDecider />}/>
                    <Route path="/login" element={<LoginPage/>}/>
                </Route>
            </Route>

            <Route element={<Layout />}>
                <Route index path="/dashboard" element={<AuthRoute><DashboardPage /></AuthRoute>} />
                <Route path="/users" element={<AuthRoute><UsersPage /></AuthRoute>} />
                <Route path="/users/:userId" element={<AuthRoute><UsersPage /></AuthRoute>} />
                <Route path="/settings" element={<AuthRoute><SettingsPage /></AuthRoute>} />

                <Route path="/projects" element={<AuthRoute><ProjectsPage /></AuthRoute>} />
                <Route path="/projects/add" element={<AuthRoute><ProjectsEditPage /></AuthRoute>} />

                <Route path="/projects/:projectId" element={<ProjectLayout/>}>
                    <Route index path="/projects/:projectId/areas" element={<AuthRoute><AreasPage /></AuthRoute>} />
                    <Route path="/projects/:projectId/settings" element={<AuthRoute><ProjectsEditPage /></AuthRoute>} />
                    <Route path="/projects/:projectId/areas/:areaId" element={<AuthRoute><AreaEditPage/></AuthRoute>} />
                    <Route path="/projects/:projectId/areas/add" element={<AuthRoute><AreaEditPage /></AuthRoute>} />
                    <Route path="/projects/:projectId/activity-types" element={<AuthRoute><ActivityTypesPage/></AuthRoute>} />
                    <Route path="/projects/:projectId/activity-types/add" element={<AuthRoute><ActivityTypesEditPage/></AuthRoute>} />
                    <Route path="/projects/:projectId/activity-types/:activityTypeId" element={<AuthRoute><ActivityTypesEditPage/></AuthRoute>} />

                </Route>
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Route>
        </Routes>
    );
}

function AuthRoute({ children }: HasChildren) {
    return (
        <RequireAuth>
            { children }
        </RequireAuth>
    )
}

function RouteDecider() {
    const auth = useAuth();
    const app = useApp();
    const location = useLocation();

    if (!app.initialized) {
        return <Pane className={'fixed top-0 left-0 w-[100vw] min-h-screen flex justify-center items-center mx-auto bg-gray-400 bg-opacity-10'}>
            <Pane className="my-20 max-w-sm flex flex-col items-center justify-center">
                <Spinner size={50} />
                <Paragraph>Loading Portal...</Paragraph>
            </Pane>
        </Pane>
    }

    if (auth.user) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
}


function RequireAuth({ children }: HasChildren) {
    const auth = useAuth();
    const location = useLocation();
    const app = useApp();

    if (!app.initialized) {
        return <Pane className={'fixed top-0 left-0 w-[100vw] min-h-screen flex justify-center items-center mx-auto bg-gray-400 bg-opacity-10'}>
            <Pane className="my-20 max-w-sm flex flex-col items-center justify-center">
                <Spinner size={50} />
                <Paragraph>Loading Portal...</Paragraph>
            </Pane>
        </Pane>
    }

    if (!auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}