import { createContext, useContext } from "react";

interface AppContextType {
  setInitialized: Function;
  initialized: boolean;
}

export function useApp() {
  return useContext(AppContext);
}

export const AppContext = createContext({
} as AppContextType);
