import { ReactNode, useEffect } from "react";
import { useApi } from "../context/AxiosContext";
import { BootstrapContext } from "../context/BootstrapContext";
import { useAuth } from "../context/AuthContext";
import { useApp } from "../context/AppContext";

export type AxiosProviderProps = {
  children?: ReactNode;
}

export function BootstrapProvider({ children }: AxiosProviderProps) {
  const { apiInstance } = useApi();
  const { setUser, jwt } = useAuth();
  const { setInitialized, initialized } = useApp();

  useEffect(() => {
    if (jwt && !initialized) {
      const bootstrap = async () => {
        const { user } = await apiInstance!.auth.profile();
        setUser!(user);
      }

      // use any settings of the user, you can set it here to be globally available as App state.
      setInitialized(true);

      bootstrap();
    }
  }, [initialized, jwt])

  return <BootstrapContext.Provider value={{}}>
    {children}
  </BootstrapContext.Provider>
}