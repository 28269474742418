import { ReactNode, useState } from "react";
import { AppContext } from "../context/AppContext";

export type AxiosProviderProps = {
  children?: ReactNode;
}

export function AppProvider({ children }: AxiosProviderProps) {
  const [initialized, setInitialized] = useState<boolean>(false);

  return <AppContext.Provider value={{ initialized, setInitialized}}>
    {children}
  </AppContext.Provider>
}