import { EmptyState, Heading, SearchIcon, Table } from "evergreen-ui";
import Block from "../../components/common/Block";
import Actions from "../../components/common/Actions";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLoadResource } from "../../lib/request-hooks";
import { useApi } from "../../context/AxiosContext";
import { Project } from "../../types/apiTypes";
import StandardTable from "../../components/table/StandardTable";
import { useTranslation } from "react-i18next";

function useProjects() {
    const [projects, setProjects] = useState<Project[]>([]);
    const { apiInstance } = useApi();

    const request = () => apiInstance!.adminProjects.findMany();

    return {
        ...useLoadResource(request, setProjects),
        projects,
    };
}

export default function ProjectsPage() {
    const { t } = useTranslation();

    const { projects, isLoading  } = useProjects();
    const navigate = useNavigate();

    const navTo = (project) => {
        navigate(`/projects/${project.id}/areas`);
    }

    return <>
        <Heading marginBottom={20}>{t('projects_page.title')}</Heading>

        <Block>
            <Actions marginBottom={10}>
                <Link to="/projects/add">
                    <Actions.Button
                        appearance="primary">{t('projects_page.add_new')}
                    </Actions.Button>
                </Link>
            </Actions>
            <StandardTable isLoading={isLoading} data={projects} emptyState={<EmptyProjectsState />}>
                <Table.Head>
                    <Table.TextHeaderCell>Id</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                    {projects.map((project) => (
                        <Table.Row key={project.id} isSelectable onSelect={() => { navTo(project)}}>
                            <Table.TextCell maxWidth={40}>{project.id}</Table.TextCell>
                            <Table.TextCell>{project.name}</Table.TextCell>
                        </Table.Row>
                    ))}

                </Table.Body>
            </StandardTable>
        </Block>
    </>
}

function EmptyProjectsState() {
    return (
        <EmptyState
            background="light"
            title="No projects found"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Projects appear when you add projects, use the button 'Add Project'"
        />
    )
}

