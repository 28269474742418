import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";

export default class AdminAreas extends BaseApi {
    findOne(id) {
      return doRequest(this._axiosInstance!, {
        method: 'get',
        url: `/admin/areas/${id}`
      });
    }

    update(id, data) {
        return doRequest(this._axiosInstance!, {
            method: 'put',
            url: `/admin/areas/${id}`,
            data: data,
        });
    }

    createForProject(projectId, data) {
      return doRequest(this._axiosInstance!, {
        method: 'post',
        url: `/admin/projects/${projectId}/areas`,
        data: data,
      });
    }

    findMany(projectId) {
      return doRequest(this._axiosInstance!, {
        method: 'get',
        url: `/admin/projects/${projectId}/areas`,
      });
    }

    remove(id) {
      return doRequest(this._axiosInstance!, {
        method: 'delete',
        url: `/admin/areas/${id}`,
      });
    }
}