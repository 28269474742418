import { Alert, Button, Card, FormField, Heading, majorScale, TextInputField } from "evergreen-ui";
import Block from "../../components/common/Block";
import { useState } from "react";
import { useApi } from "../../context/AxiosContext";
import { useAuth } from "../../context/AuthContext";
import { useDoRequest } from "../../lib/request-hooks";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
    const {handle, isLoading, errorMessage, successMessage} = useDoRequest();

    const { apiInstance } = useApi();
    const { setJwt, setUser } = useAuth();

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("");

    const navigate = useNavigate();

    const submit = async () => {
        const request = apiInstance!.auth.login({ identifier: email, password });

        const { jwt, user } = await handle(request);

        setJwt!(jwt);
        setUser!(user)

        navigate(`/dashboard`);
    }

    return (
        <Block className={'max-w-[25rem] mt-32 mx-auto'}>
            <Block className="my-20 max-w-sm">
                <img src={"/logo.png"} />
            </Block>
            <Card elevation={1} className={"p-8"}>
                <Block className={'flex flex-col gap-5 justify-center align-items'}>
                    <Heading>Grofalex Portal</Heading>
                    <Block className="flex flex-col">
                        <FormField label="">
                            <TextInputField
                                marginBottom={0}
                                inputHeight={40}
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormField>
                        <FormField label="">
                            <TextInputField
                                marginBottom={0}
                                inputHeight={40}
                                name="password"
                                placeholder="Password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormField>
                    </Block>

                    {errorMessage &&
                      <Alert
                        intent="danger"
                        title={errorMessage}
                        marginBottom={32}
                      />
                    }

                    <Block className={"flex justify-end"}>
                        <Button
                            onClick={submit}
                            isLoading={!!isLoading}
                            appearance="primary"
                            height={majorScale(5)}>Login</Button>
                    </Block>

                </Block>
            </Card>
        </Block>
    );
}