import { Alert, FormField, Heading, majorScale, Pane, Spinner, TextInputField } from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/common/Block";
import { useState } from "react";
import { useApi } from "../../context/AxiosContext";
import Form from "../../components/common/Form";
import Actions from "../../components/common/Actions";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";

const useProjectFormFields = function () {
    const { projectId } = useParams();
    const [ name, setName ] = useState<string>("");
    const { apiInstance } = useApi();

    const find = () => apiInstance!.adminProjects.findOne(projectId);

    const setData = (project) => {
        setName(project.name);

        return Promise.resolve();
    }

    return {
        ...useLoadResource(find, setData, !!projectId),
        name,
        setName
    };
}

export default function ProjectsEditPage() {
    const { apiInstance } = useApi();
    const navigate = useNavigate();
    const { projectId } = useParams();

    const { handle, isLoading: isSubmitting, errorMessage: submitErrorMessage } = useDoRequest();

    const { name, setName, isLoading } = useProjectFormFields();

    const submit = async () => {
        let request;

        if (projectId) {
            request = apiInstance!.adminProjects.update(projectId, { name });
        } else {
            request = apiInstance!.adminProjects.create({ name });
        }

        await handle(request);

        navigate('/projects')
    }


    return <Block>
        <Block className="flex gap-2 w-full">
            <Heading marginBottom={20}>Project</Heading>

            {isLoading &&
              <Block className="flex justify-end">
                <Spinner size={20}/>
              </Block>
            }
        </Block>

        <Form>
            <FormField label="Name">
                <TextInputField
                    marginBottom={0}
                    inputHeight={40}
                    label=""
                    name="name"
                    placeholder="Project X Y Z"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormField>

            {submitErrorMessage &&
              <Pane>
                <Alert
                  marginTop={10}
                  intent="danger"
                  title={submitErrorMessage}
                />
              </Pane>
            }

            <Actions marginTop={10} marginBottom={10}>
                <Actions.Button
                    onClick={submit}
                    intent="success"
                    isLoading={!!isSubmitting}
                    appearance="primary"
                    height={majorScale(5)}>Save
                </Actions.Button>
            </Actions>
        </Form>
    </Block>
}