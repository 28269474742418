import {
    Checkbox,
    Dialog,
    EmptyState,
    FormField,
    Heading,
    Pane,
    Paragraph,
    SearchIcon,
    Table,
    TextInputField
} from "evergreen-ui";

import Actions from "../../components/common/Actions";
import Block from "../../components/common/Block";
import Form from "../../components/common/Form";
import StandardTable from "../../components/table/StandardTable";

import { User } from "../../types/apiTypes";
import { useApi } from "../../context/AxiosContext";
import { useLoadResource } from "../../lib/request-hooks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useUsers = function () {
    const [users, setUsers] = useState<User[]>([]);
    const { apiInstance } = useApi();

    const request = () => apiInstance!.adminUsers.findMany();

    return {
        ...useLoadResource(request, setUsers),
        users,
    };
}

function InviteDialog({ isOpen, close }) {
    const { apiInstance } = useApi();

    const [ email, setEmail ] = useState<string>("");
    const [ checkedValue, setCheckedValue] = useState<string>("")

    const userTypes = [
        {
            label: "User",
            value: 'user',
        },
        {
            label: "Worker",
            value: 'worker',
        },
    ];

    const sendInvite = async () => {
        if (checkedValue === 'user') {
            await apiInstance!.adminUsers.inviteUser({ email });
        }

        if (checkedValue === 'worker') {
            await apiInstance!.adminUsers.inviteWorker({ email } );
        }

        close();
    }

    return (

        <Dialog
            isShown={isOpen}
            title="Invite a User or a Worker"
            onConfirm={() => sendInvite()}
            confirmLabel="Send Invite"
            onCloseComplete={close}
        >
            {({ close }) => (
                <Pane>
                    <Paragraph size={50}>
                        Select the kind of User you want to invite.
                    </Paragraph>

                    <Pane>
                        {userTypes.map((userType) => (
                            <Checkbox
                                label={userType.label}
                                checked={checkedValue === userType.value}
                                onChange={() => setCheckedValue(userType.value)}
                            />
                        ))}
                    </Pane>

                    <Pane className="py-5 max-w-sm">
                        <Form>
                            <FormField label="Email">
                                <TextInputField
                                    marginBottom={0}
                                    inputHeight={40}
                                    size={50}
                                    name="email"
                                    placeholder=""
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormField>
                        </Form>
                    </Pane>
                </Pane>
            )}
        </Dialog>
    )
}

function EmptyUsersState() {
    return (
        <EmptyState
            background="light"
            title="No users found"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Users appear when there are users"
            anchorCta={
                <EmptyState.LinkButton href="https://segment.com/docs/" target="_blank">
                    Learn how to ...
                </EmptyState.LinkButton>
            }
        />
    )
}

export default function UsersPage() {
    const { t } = useTranslation();
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false)

    const { users, isLoading } = useUsers();

    const navigate = useNavigate();

    const navTo = (user) => {
        navigate(`/users/${user.id}`);
    }

    return <>
        <InviteDialog
            isOpen={isInviteDialogOpen}
            close={() => setIsInviteDialogOpen(false)}
        />

        <Heading marginBottom={20}>{t('users_page.title')}</Heading>

        <Block>
            <Actions marginBottom={10}>
                <Actions.Button onClick={() => setIsInviteDialogOpen(true)}
                    appearance="primary">Invite User / Worker
                </Actions.Button>
            </Actions>
            <StandardTable isLoading={isLoading} data={users} emptyState={<EmptyUsersState />}>
                <Table.Head>
                    <Table.TextHeaderCell maxWidth={50}>Id</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Email</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                    {users.map((user) => (
                        <Table.Row key={user.id} isSelectable onSelect={() => { navTo(user)}}>
                            <Table.TextCell className="!border-r" maxWidth={50}>{user.id}</Table.TextCell>
                            <Table.TextCell className="!border-r">{user.firstName} {user.lastName}</Table.TextCell>
                            <Table.TextCell>{user.email}</Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </StandardTable>
        </Block>
    </>
}
