import { Alert, FormField, Heading, majorScale, Pane, Spinner, TextInputField } from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "../../../context/AxiosContext";
import { useDoRequest, useLoadResource } from "../../../lib/request-hooks";
import Block from "../../../components/common/Block";
import Form from "../../../components/common/Form";
import Actions from "../../../components/common/Actions";
import DatePicker from "../../../components/formfields/DatePicker";
import { parseISO } from "date-fns";
import SelectInputField from "../../../components/formfields/SelectInputField";
import { ActivityTypeOptions, mapToActivityOption } from "../../../types/activity-types";

function useActivityTypeFormFields() {
    const navigate = useNavigate();
    const { apiInstance } = useApi();
    const { activityTypeId, projectId } = useParams();

    const [name, setName ] = useState<string>("");
    const [endAt, setEndsAt] = useState<Date | null>(null)
    const [minDaysBetweenRounds, setMinDaysBetweenRounds] = useState<string>("")
    const [numberOfRounds, setNumberOfRounds] = useState<string>("")
    const [selectedType, setSelectedType] = useState<ActivityTypeOption | null>(null)
    const [startAt, setStartAt] = useState<Date | null>(null)

    const { handle, isLoading: isSubmitting, errorMessage: submitErrorMessage } = useDoRequest();

    const submit = async () => {
        let request;

        const data = {
            name,
            type: selectedType!.value,
            startAt,
            endAt,
            numberOfRounds,
            minDaysBetweenRounds,
        };

        if (activityTypeId) {
            request = apiInstance!.adminActivityTypes.update(activityTypeId, data);
        } else {
            request = apiInstance!.adminActivityTypes.createForProject(projectId, data);
        }

        await handle(request);

        navigate(`/projects/${projectId}/activity-types`)
    }

    const find = () => apiInstance!.adminActivityTypes.findOne(activityTypeId);

    const set = (activityType) => {
        return new Promise((resolve) => {
            setName(activityType.name);
            setEndsAt(activityType.endAt ? parseISO(activityType.endAt) : null)
            setMinDaysBetweenRounds(activityType.minDaysBetweenRounds)
            setNumberOfRounds(activityType.numberOfRounds)
            setSelectedType(mapToActivityOption(activityType.type))
            setStartAt(activityType.startAt ? parseISO(activityType.startAt) : null)

            resolve(true)
        });
    }

    return {
        ...useLoadResource(find, set, !!activityTypeId),
        name,
        setName,
        selectedType,
        setSelectedType,
        startAt,
        setStartAt,
        endAt,
        setEndsAt,
        numberOfRounds,
        setNumberOfRounds,
        minDaysBetweenRounds,
        setMinDaysBetweenRounds,
        submit,
        isSubmitting,
        submitErrorMessage,
    };
}

export default function ActivityTypesEditPage() {
    const { activityTypeId } = useParams();

    const {
        name,
        setName,
        selectedType,
        setSelectedType,
        startAt,
        setStartAt,
        endAt,
        setEndsAt,
        numberOfRounds,
        setNumberOfRounds,
        minDaysBetweenRounds,
        setMinDaysBetweenRounds,
        isLoading,
        submit,
        isSubmitting,
        submitErrorMessage
    } = useActivityTypeFormFields();

    return (
        <Block>
            <Block className="flex gap-2 w-full">
                <Heading marginBottom={20}>{ !activityTypeId ? "Create a new ": "Edit "}
                   ActivityType
                </Heading>

                {isLoading &&
                  <Block className="flex justify-end">
                    <Spinner size={20}/>
                  </Block>
                }
            </Block>

            <Form>
                <FormField>
                    <TextInputField
                        marginBottom={0}
                        inputHeight={40}
                        maxWidth={500}
                        name="name"
                        label="Name"
                        placeholder="e.g., counting birds ... "
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormField>

                <FormField label="Type">
                    <SelectInputField
                        selected={selectedType}
                        setSelected={setSelectedType}
                        title="Select type"
                        emptySelectText="Select a type of activity..."
                        options={Object.values(ActivityTypeOptions)}
                    />
                </FormField>

                <FormField>
                    <TextInputField
                        marginBottom={0}
                        inputHeight={40}
                        maxWidth={200}
                        name="Rounds"
                        label="Rounds"
                        placeholder="e.g., 5"
                        value={numberOfRounds}
                        onChange={(e) => setNumberOfRounds(e.target.value)}
                    />
                </FormField>

                <FormField>
                    <TextInputField
                        marginBottom={0}
                        inputHeight={40}
                        maxWidth={200}
                        name="Days between rounds"
                        label="Days between rounds"
                        placeholder="e.g., 5 days"
                        value={minDaysBetweenRounds}
                        onChange={(e) => setMinDaysBetweenRounds(e.target.value)}
                    />
                </FormField>

                <FormField label="Start / end date">
                    <Block className="flex gap-3">
                        <DatePicker
                            selected={startAt}
                            setSelected={setStartAt}
                            title="Select date"
                            emptySelectText="Select the start date..."
                        />
                        <DatePicker
                            selected={endAt}
                            setSelected={setEndsAt}
                            title="Select date"
                            emptySelectText="Select the end date..."
                        />
                    </Block>
                </FormField>

                {submitErrorMessage &&
                  <Pane>
                    <Alert
                      marginTop={10}
                      intent="danger"
                      title={submitErrorMessage}
                    />
                  </Pane>
                }

                <Actions marginTop={10} marginBottom={10}>
                    <Actions.Button
                        onClick={submit}
                        isLoading={!!isSubmitting}
                        appearance="primary"
                        intent="success"
                        height={majorScale(5)}>Save
                    </Actions.Button>
                </Actions>
            </Form>
        </Block>
    )
}

interface SelectOption {
    label: string;
    value: any;
}

interface ActivityTypeOption extends SelectOption {
    numberOfRounds: number;
    minDaysBetweenRounds: number;
}
