import { Alert, FormField, Heading, majorScale, Pane, Spinner, TextInputField } from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "../../../context/AxiosContext";
import { useDoRequest, useLoadResource } from "../../../lib/request-hooks";
import Block from "../../../components/common/Block";
import Form from "../../../components/common/Form";
import Actions from "../../../components/common/Actions";
import { ActivityType, Address, Area } from "../../../types/apiTypes";
import ActivityTypesSelect from "../../../components/activity-types/ActivityTypesSelect";
import AddressInputField from "../../../components/formfields/AddressInputField";

const useAreaForm = function () {
    const { areaId, projectId } = useParams();
    const { apiInstance } = useApi();

    const { handle, isLoading: isSubmitting, errorMessage: submitErrorMessage } = useDoRequest();
    const find = () => apiInstance!.adminAreas.findOne(areaId);

    const [ name, setName ] = useState<string>("");
    const [ address, setAddress] = useState<Address | null>(null);
    const [ activityTypes, setActivityTypes] = useState<ActivityType[]>([]);

    const doSubmit = async () => {
        const data = {
            name,
            activityTypes,
            addresses: address ? [{
                city: address.city,
                cityShortName: address.cityShortName,
                country: address.country,
                countryShortName: address.countryShortName,
                formattedAddress: address.formattedAddress,
                googlePlaceId: address.googlePlaceId,
                lat: address.lat,
                lng: address.lng,
                number: address.number,
                numberAddition: address.numberAddition,
                postalCode: address.postalCode,
                province: address.province,
                provinceShortName: address.provinceShortName,
                street: address.street,
                url: address.url,
                viewport: address.viewport,
            }]: null
        }

        let request;

        if (areaId) {
            request = apiInstance!.adminAreas.update(areaId, data);
        } else {
            request = apiInstance!.adminAreas.createForProject(projectId, data);
        }

        await handle(request);
    }

    const setData = (area: Area) => {
        setName(area.name);
        setAddress(area.addresses?.[0] || []);
        setActivityTypes(area.activityTypes);
    }

    return {
        ...useLoadResource(find, setData, !!areaId),
        name,
        setName,
        address,
        setAddress,
        activityTypes,
        setActivityTypes,
        submitHandle: { doSubmit, isSubmitting, submitErrorMessage}
    };
}

export default function AreaEditPage() {
    const navigate = useNavigate();
    const { areaId, projectId } = useParams();

    const {
        name,
        setName,
        isLoading,
        activityTypes,
        setActivityTypes,
        submitHandle,
        address,
        setAddress
    } = useAreaForm();

    const submit = async () => {
        await submitHandle.doSubmit();
        navigate(`/projects/${projectId}/areas`)
    }

    return <Block>
        <Block className="flex gap-2 w-full">
            <Heading marginBottom={20}>{ !areaId ? "Create a new ": "Edit "}
                Area
            </Heading>

            {isLoading &&
              <Block className="flex justify-end">
                <Spinner size={20} />
              </Block>
            }
        </Block>

        {isLoading === false &&
          <Form>
            <FormField>
              <TextInputField
                marginBottom={0}
                inputHeight={40}
                name="name"
                label="Name"
                placeholder="Area at ..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormField>

            <FormField label={"Address for this Area"}>
              <AddressInputField
                address={address}
                setAddress={setAddress}
              />
            </FormField>

            <FormField label={"Select the Activities for this Area"}>
              <ActivityTypesSelect
                projectId={projectId!}
                selected={activityTypes}
                setSelected={setActivityTypes}
              />
            </FormField>

              {submitHandle.submitErrorMessage &&
                <Pane>
                  <Alert
                    marginTop={10}
                    intent="danger"
                    title={submitHandle.submitErrorMessage}
                  />
                </Pane>
              }

            <Actions marginTop={10} marginBottom={10}>
              <Actions.Button
                onClick={submit}
                isLoading={!!submitHandle.isSubmitting}
                appearance="primary"
                intent="success"
                height={majorScale(5)}>Save
              </Actions.Button>
            </Actions>
          </Form>
        }
    </Block>
}