import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";

export default class AdminUsers extends BaseApi {
  findOne(id) {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/users/${id}`
    });
  }

  findMany() {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/users`,
    });
  }

  inviteUser(data) {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/users/invite-user`,
      data: data,
    });
  }

  inviteWorker(data) {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/users/invite-worker`,
      data: data,
    });
  }

  remove(id) {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/users/${id}`,
    });
  }
}