import React, { Suspense } from 'react';
import './App.css';
import { AxiosProvider } from "./providers/AxiosProvider";
import { AppProvider } from "./providers/AppProvider";
import { AuthProvider } from "./providers/AuthProvider";
import AppRoutes from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { BootstrapProvider } from "./providers/BootstrapProvider";

function App() {
    return (
        <Suspense>
            <AppProvider>
                <AuthProvider>
                    <AxiosProvider>
                        <BootstrapProvider>
                            <BrowserRouter>
                                <AppRoutes />
                            </BrowserRouter>
                        </BootstrapProvider>
                    </AxiosProvider>
                </AuthProvider>
            </AppProvider>
        </Suspense>
  );
}

export default App;
