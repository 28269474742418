import {
    Avatar,
    DashboardIcon, GitRepoIcon,
    Heading,
    LogOutIcon,
    Menu,
    Pane,
    PersonIcon,
    Popover,
    Position,
    SettingsIcon, UserIcon
} from "evergreen-ui";
import Block from "../common/Block";
import { useAuth } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "../../lib/functions";

const menuItems = [
    {
        label: 'Dashboard',
        pathname: '/dashboard',
        icon: <DashboardIcon/>
    },
    {
        label: 'Projects',
        pathname: '/projects',
        icon: <GitRepoIcon />
    },
    {
        label: 'Users',
        pathname: '/users',
        icon: <UserIcon />
    },
];

const MenuItem = function({ location, item }) {
    const isActive = location.pathname.startsWith(item.pathname);
    const className = classNames(isActive ? '!bg-gray-100': '');

    return (
        <Link to={item.pathname}>
            <Menu.Item className={className} icon={item?.icon}>{item.label}</Menu.Item>
        </Link>
    )
}

export default function MainNavigationMenu() {
    const { user, setUser, setJwt } = useAuth();
    const location = useLocation();

    const logout = () => {
        setJwt!(null);
        setUser!(null);
    }

    return (
        <Block className="h-screen">
            <Block className="h-full flex flex-col">
                <Block className="flex-grow">
                    <Block className="px-5 py-10 max-w-[15rem] text-3xl">
                        <img src={"/logo.png"}  alt="grofalex logo" />
                    </Block>
                    <Block>
                        <Menu>
                            <Menu.Group>
                                {menuItems.map((item) => (
                                    <MenuItem location={location} item={item} />
                                ))}
                            </Menu.Group>
                            <Menu.Divider />
                        </Menu>
                        <Menu>
                            <Menu.Group>
                                <Link to="/settings">
                                    <Menu.Item icon={SettingsIcon}>Settings</Menu.Item>
                                </Link>
                            </Menu.Group>
                            <Menu.Divider />
                        </Menu>
                    </Block>
                </Block>
                <Block>
                    <Menu.Divider />
                    <Menu>
                        <Menu.Group>
                                <Popover
                                    bringFocusInside
                                    position={Position.TOP_RIGHT}
                                    content={
                                        <Pane
                                            width={200}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="column"
                                        >
                                            <Menu>
                                                <Menu.Group>
                                                    <Menu.Item icon={PersonIcon}>Account Settings</Menu.Item>
                                                </Menu.Group>
                                                <Menu.Divider />
                                                <Menu.Group>
                                                    <Menu.Item
                                                        onClick={logout}
                                                        icon={LogOutIcon} intent="danger">Logout</Menu.Item>
                                                </Menu.Group>
                                            </Menu>
                                            {/*<Button*/}
                                            {/*    onClick={logout}*/}
                                            {/*    appearance="primary">Logout</Button>*/}
                                        </Pane>
                                    }>
                                    <Menu.Item height={80} paddingTop={20} paddingBottom={20}>
                                        <Heading>
                                            <Avatar name={`${user?.firstName}} ${ user?.lastName }`} size={40} />
                                        </Heading>
                                    </Menu.Item>
                                </Popover>
                        </Menu.Group>
                    </Menu>
                </Block>
            </Block>
        </Block>
    )
}