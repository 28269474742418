import { Button, SelectMenu } from "evergreen-ui";
import { useEffect, useState } from "react";

const MultiSelectInputField = function({ emptySelectText, options, selected, setSelected, ...rest }) {
    const [selectedItemNamesState, setSelectedItemNames] = useState<string | null>(null)
    const selectedAsValues = selected.map((v) => v.value);

    useEffect(() => {
        let selectedNames = ''
        const selectedItemsLength = selected.length

        if (selectedItemsLength === 0) {
            selectedNames = ''
        } else if (selectedItemsLength <= 3) {
            selectedNames = selected.map(v => v.label).join(', ')

        } else if (selectedItemsLength > 3) {
            selectedNames = selectedItemsLength.toString() + ' Activities'
        }

        setSelectedItemNames(selectedNames)
    }, [selected])

    const onSelect = (item) => {
        const selectedItems = [...selected, item]
        setSelected(selectedItems)
    }

    const onDeselect = (item) => {
        const deselectedItemIndex = selectedAsValues.indexOf(item.value)
        const selectedItems = selected.filter((_item, i) => i !== deselectedItemIndex)
        setSelected(selectedItems)
    }

    return (
        <SelectMenu
            isMultiSelect
            options={options}
            selected={selectedAsValues}
            onSelect={onSelect}
            onDeselect={onDeselect}
            {...rest}
        >
            <Button>{selectedItemNamesState || emptySelectText}</Button>
        </SelectMenu>
    )
}

export default MultiSelectInputField;
