import { Button, Pane } from 'evergreen-ui';

const ActionButton = function(props) {
    const { children, ...rest } = props

    return (
        <Button {...rest}>
            { children }
        </Button>
    )
}

const Actions = function Actions(props) {
    const { children, ...rest } = props

    return (
        <Pane className={'flex gap-2 justify-end'} {...rest}>
            {children}
        </Pane>
    )
}

Actions.Button = ActionButton;

export default Actions;