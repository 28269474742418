import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useApi } from "../../../context/AxiosContext";
import { ActivityType } from "src/types/apiTypes";
import { useLoadResource } from "../../../lib/request-hooks";
import { EmptyState, Heading, Pane, SearchIcon, Table } from "evergreen-ui";
import Actions from "../../../components/common/Actions";
import StandardTable from "../../../components/table/StandardTable";
import DateValue from "../../../components/common/DateValue";
import { mapToActivityOption } from "../../../types/activity-types";

export function useActivityTypes() {
    const { projectId } = useParams();
    const [activityTypes, setActivityTypes] = useState<ActivityType[]>([]);

    const { apiInstance } = useApi();

    const request = () => apiInstance!.adminActivityTypes.findMany(projectId);

    return {
        ...useLoadResource(request, setActivityTypes),
        activityTypes,
    };
}

export default function ActivityTypesPage() {
    const { projectId } = useParams();

    const { activityTypes, isLoading, errorMessage, successMessage  } = useActivityTypes();
    const navigate = useNavigate();

    const navTo = (activity) => {
        navigate(`/projects/${projectId}/activity-types/${activity.id}`);
    }

    return <>
        <Heading marginBottom={20}>ActivityTypes</Heading>

        <Pane>
            <Actions marginBottom={10}>
                <Link to={`/projects/${projectId}/activity-types/add`}>
                    <Actions.Button
                        appearance="primary">New Activity Type
                    </Actions.Button>
                </Link>
            </Actions>
            <StandardTable isLoading={isLoading} data={activityTypes} emptyState={<EmptyActivityTypesState />}>
                <Table.Head>
                    <Table.TextHeaderCell maxWidth={80}>Id</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Type</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Start / End</Table.TextHeaderCell>
                    <Table.TextHeaderCell maxWidth={80}># Rounds</Table.TextHeaderCell>
                    <Table.TextHeaderCell maxWidth={80}># Days Between</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                    {activityTypes.map((activity) => (
                        <Table.Row key={activity.id} isSelectable onSelect={() => { navTo(activity)}}>
                            <Table.TextCell maxWidth={80}>{activity.id}</Table.TextCell>
                            <Table.TextCell>{activity.name}</Table.TextCell>
                            <Table.TextCell>{mapToActivityOption(activity.type)?.label}</Table.TextCell>
                            <Table.TextCell>
                                <DateValue dateString={activity.startAt} /> - <DateValue dateString={activity.endAt}/>
                            </Table.TextCell>
                            <Table.TextCell maxWidth={80}>{activity.numberOfRounds}</Table.TextCell>
                            <Table.TextCell maxWidth={80}>{activity.minDaysBetweenRounds}</Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </StandardTable>
        </Pane>
    </>
}

function EmptyActivityTypesState() {
    return (
        <EmptyState
            background="light"
            title="No areas found"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Areas appear when you add areas, use the button 'New Area'"
        />
    )
}

