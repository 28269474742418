import { Spinner, Table } from "evergreen-ui";
import Block from "../common/Block";
import { isEmpty } from 'lodash';

function TableLoadingSpinner () {
    return (
        <Block className="min-h-[10rem]" fullWidth center>
            <Spinner/>
        </Block>
    )
}

export default function StandardTable({ children, data, isLoading, emptyState }) {
    return (
        <Table width="100%">
            { children }

            {isLoading &&
                <TableLoadingSpinner />
            }

            {isLoading === false && isEmpty(data) && emptyState }
        </Table>
    )
}