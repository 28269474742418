import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";

export default class AdminActivityTypes extends BaseApi {
  findOne(id) {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/activity-types/${id}`
    });
  }

  update(id, data) {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/activity-types/${id}`,
      data: data,
    });
  }

  findMany(projectId) {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/projects/${projectId}/activity-types`,
    });
  }

  createForProject(projectId, data) {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/projects/${projectId}/activity-types`,
      data: data,
    });
  }

  remove(id) {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/activity-types/${id}`,
    });
  }
}