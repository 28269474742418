import { classNames } from '../../lib/functions';

const classMap = {
    fullWidth: 'w-full',
    fullHeight: 'h-full',
    center: 'flex justify-center items-center',
}

export default function Block({ style = {}, children, fullWidth = false, fullHeight = false, center = false, className = '' }) {
    const blockClassNames = classNames('block',
        className,
        fullWidth ? classMap.fullWidth: '',
        fullHeight ? classMap.fullHeight: '',
        center ? classMap.center: ''
    );

    return (
        <div style={style}
             className={blockClassNames}
        >{children}</div>
  );
}