import PropTypes from 'prop-types';
import { Button, SelectMenu } from "evergreen-ui";

const SelectInputField = function({ title, emptySelectText, options, selected, setSelected, ...rest }) {
    return (
        <SelectMenu
            title={title}
            options={options}
            selected={selected?.label}
            onSelect={(item) => setSelected(item)}
            {...rest}
        >
            <Button>{selected?.label || emptySelectText}</Button>
        </SelectMenu>
    )
}

SelectInputField.propTypes = {
    title: PropTypes.string,
    emptySelectText: PropTypes.string,
    options: PropTypes.array,
    setSelected: PropTypes.func,
    selected: PropTypes.object
}


export default SelectInputField;
