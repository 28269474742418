import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";

export default class AdminProjects extends BaseApi {
  public findOne(id) {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/projects/${id}`
    });
  }

  public create(data) {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/projects`,
      data: data,
    });
  }

  public update(id, data) {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/projects/${id}`,
      data: data,
    });
  }

  public findMany() {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/projects`,
    });
  }

  public remove(id) {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/projects/${id}`,
    });
  }

}
//
// export const _adminProjects = (axiosInstance) => {
//   return {
//     findOne,
//     create,
//     remove,
//     findMany,
//     update,
//   }
// }