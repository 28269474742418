import { Heading, Pane, Tab, TabNavigation } from "evergreen-ui";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import Block from "../common/Block";
import { useApi } from "../../context/AxiosContext";
import { useLoadResource } from "../../lib/request-hooks";
import { useState } from "react";
import { Project } from "../../types/apiTypes";

const useProject = function () {
  const { projectId } = useParams();
  const [ project, setProject ] = useState<Project>();
  const { apiInstance } = useApi();

  const find = () => apiInstance!.adminProjects.findOne(projectId);

  const setData = (project) => {
    setProject(project);
    return Promise.resolve();
  }

  return {
    ...useLoadResource(find, setData, !!projectId),
    project
  };
}

export default function ProjectLayout() {
  const { projectId } = useParams();
  const location = useLocation();
  const { project, isLoading } = useProject();

  const tabItems = [
    {
      label: 'Areas',
      pathname: `/projects/${projectId}/areas`,
    },
    {
      label: 'Activity Types',
      pathname: `/projects/${projectId}/activity-types`,
    },
    {
      label: 'Project Settings',
      pathname: `/projects/${projectId}/settings`,
    },
  ];

  const isSelected = (tabItem) => {
    return location.pathname.startsWith(tabItem.pathname);
  }

  return (
    <Pane>
      <Block className="pb-5 flex gap-1">
        <Link to={"/projects"}>
          <Heading size={800} className="hover:underline cursor-pointer">Projects</Heading>
        </Link>
        <Heading size={800}>/</Heading>
        <Heading className="!text-2xl" size={100}>{project?.name}</Heading>
      </Block>
      <TabNavigation>
        {tabItems.map((tab, index) => (
          <Link key={tab.pathname} to={tab.pathname}>
            <Tab key={tab.pathname} id={tab.label} isSelected={isSelected(tab)}>
              {tab.label}
            </Tab>
          </Link>
        ))}
      </TabNavigation>

      <Pane marginTop={20}>
        <Outlet />
      </Pane>
    </Pane>
  );
}