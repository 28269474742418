import { DayPicker } from "react-day-picker";
import { Button, Popover, Position } from "evergreen-ui";
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';

const DatePicker = function({ emptySelectText, selected, setSelected, ...rest }) {
    return (
        <Popover
            position={Position.BOTTOM_LEFT}
            content={
                <DayPicker
                    mode="single"
                    selected={selected}
                    onSelect={setSelected}
                    {...rest}
                />
            }
        >
            <Button>{selected ? format(selected, 'PP') : emptySelectText}</Button>
        </Popover>
    )
}

export default DatePicker;
